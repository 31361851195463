.App {
  background-color: #F3F3F4;
}
h1 {
  display: flex;
  align-items: center;
  justify-content: center;
}


.App-header {
  background-color: #515151;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.pru-header {
  height: 10%;
  display: flex;
  align-items: center;
  margin-left: 51px;
  padding-top: 40px;
}

.ant-card-head-title {
  background-color:#F3F3F4 ;
}

.ant-card .ant-card-head {
  background-color: #F3F3F4;
}
