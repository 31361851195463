.ant-tabs-tab {
    width: 873px;
    font-size: 64px;
    font-weight: 700;
    justify-content: center;
    background-color: #80B2F2;

}

.ant-tabs-card >.ant-tabs-nav .ant-tabs-tab-active{
    background-color:#80B2F2 ;
    .ant-tabs-tab-btn {
        color: black
    }
}

.pru-tabs {
    background-color: white;
}
.pru-filter {
    padding-left: 10px;
    padding-right: 10px;
}
.ant-btn-primary {
    background-color: #357AB4;
    padding-left: 5px;
}

.ant-typography {
    font-weight: bold;
    padding-left: 5px;
    padding-right: 5px;
}
